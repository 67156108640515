
<template>
  <div>
    <div class="search-bar">
      <search @inputChange="searchInput" @btnSubmit="searchSubmit" :showBtn="true" label="Vyhľadať poukaz" />
    </div>
    <div v-if="vouchers" id="voucher-table" class="custom-table voucher">
      <table-actions :actions="
        userData && userData.role === 'ROLE_ADMIN'
          ? ['search', 'perPage' /*'addBtn'*/]
          : ['search', 'perPage']
      " :addBtnText="$t('voucher.add')" :addBtnLink="$helper.getAddUrl('voucher')" @onSearchInput="inputChange"
        @perPageChange="perPageChange" :defaultPerPage="perPage" />
      <table-lite :is-slot-mode="true" :is-loading="isLoading" :columns="columns" :rows="vouchers" :sortable="{}"
        @is-finished="
          () => {
            isLoading = false;
            windowResize();
          }
        ">
        <template v-slot:code="data">
          <router-link :to="$helper.getEditUrl('voucher', data.value.id)">
            {{ data.value.code }}
          </router-link>
        </template>
        <template v-slot:order="data">
          <router-link :to="$helper.getEditUrl('order', data.value.order.id)">
            {{ data.value.order.id }}
          </router-link>
        </template>
        <template v-slot:partner="data">
          <router-link :to="$helper.getEditUrl('partner', data.value.partner.numberId)">
            {{ data.value.partner.label }}
          </router-link>
        </template>
        <template v-slot:passengers="data">
          <div class="voucher-client" v-if="data.value.passengers && data.value.passengers.length">
            <strong>{{
              data.value.passengers[0].givenName
              ? data.value.passengers[0].givenName
              : ""
            }}
              {{
                data.value.passengers[0].familyName
                ? data.value.passengers[0].familyName
                : ""
              }}</strong>
            <div>{{ data.value.passengers[0].email }}</div>
            <div>{{ data.value.passengers[0].telephone }}</div>
          </div>
        </template>
        <template v-slot:date="data">{{
          $helper.formatDate(data.value.order.createdAt)
        }}</template>
        <template v-slot:product="data">
          <div v-if="data.value.voucherVarinat" class="voucher-product">
            <div v-if="data.value.voucherVarinat.fileUrl" class="voucher-product-image">
              <img :src="data.value.voucherVarinat.fileUrl" alt="" />
            </div>
            <div class="voucher-product-info">
              <h4>{{ data.value.voucherVarinat.name }}</h4>
              <table
                  v-if="userData.role === 'ROLE_PARTNER'"
              >
                  <tr v-for="(conf, index) in orderProductConfigurationsToView(data.value.voucherVarinat.productConfigurations)" :key="index">
                  <td colspan="2"><strong>● {{ conf.name }}</strong></td>
                </tr>
                <tr v-for="(key, index) in data.value.voucherVarinat.customParameters" :key="index">
                  <td>{{ index }}:</td>
                  <td>{{ key }}</td>
                </tr>
              </table>
              <table
                v-else
              >
                <tr v-for="(conf, index) in orderProductConfigurationsToView(data.value.voucherVarinat.productConfigurations)" :key="index">
                  <td><strong>● {{ conf.name }}:</strong></td>
                  <td><strong>+{{ $helper.priceFormat(conf.priceWithTax, 2) }}</strong></td>
                </tr>
                <tr v-for="(key, index) in data.value.voucherVarinat.customParameters" :key="index">
                  <td>{{ index }}:</td>
                  <td>{{ key }}</td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template v-slot:price="data">
          {{
            data.value.voucherVarinat && data.value.voucherVarinat.purchasePrice
            ? $helper.priceFormat(data.value.voucherVarinat.purchasePrice, 2)
            : $helper.priceFormat(0, 2)
          }}
        </template>
        <template v-slot:status="data">
          <b-badge :variant="statusClass(data.value.status)">
            {{
              $helper.getEnumTranslation(
                "voucher_status",
                data.value.status,
                $i18n.locale
              )
            }}
          </b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('voucher', data.value.id)" />
            <delete-btn v-if="enableDelete" @pressDelete="deleteVoucher(data.value['@id'])" />
            <div v-if="data.value.status === 'ACTIVATED'" @click="windowResize" :key="`collapse-key-${data.value.id}`">
              <div v-b-toggle="`collapse-${data.value.id}`" class="collpase-arrow custom-collapse">
                <img src="@/assets/images/icons/arrow-up.svg" />
              </div>
            </div>
          </div>
          <div v-if="data.value.status === 'ACTIVATED'" class="collapsible-content-row">
            <b-collapse class="" :id="`collapse-${data.value.id}`">
              <div class="redeem-form">
                <label>{{ $t("voucher.password") }}</label>
                <b-form-input v-model="data.value.password" />
                <b-button variant="primary" @click="redeem(data.value)">{{
                  $t("voucher.redeem")
                }}</b-button>
              </div>
              <table class="redeem-form-table">
                <tr>
                  <td>
                    <strong>{{ $t("voucher.activationDate") }}:</strong>
                  </td>
                  <td>{{ $helper.formatDate(data.value.activationDate) }}</td>
                </tr>
                <tr>
                  <td>
                    <strong>{{ $t("voucher.validityDate") }}:</strong>
                  </td>
                  <td>{{ $helper.formatDate(data.value.validUntil) }}</td>
                </tr>
              </table>
            </b-collapse>
          </div>
        </template>
      </table-lite>
      <table-pagination v-if="pages" v-model="currentPage" :total="pages" :per-page="perPage" @input="onChangePage" />
    </div>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import Search from "@/components/Inputs/Search.vue";
import Toast from "@/components/Toast.vue";
import { useToast } from "vue-toastification";

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    Search,
  },
  mixins: [ListUtils, ResourceUtils],
  data() {
    let columns = [
        {
            label: this.$t("voucher.code"),
            field: "code",
            sortable: false,
        },
        {
            label: this.$t("voucher.client"),
            field: "passengers",
            sortable: false,
        },
        {
            label: this.$t("voucher.date"),
            field: "date",
            sortable: false,
        },
        {
            label: this.$t("voucher.product"),
            field: "product",
            sortable: false,
        }
    ];

    columns.push({
        label: this.$t("voucher.price"),
        field: "price",
        sortable: false,
    });

    columns.push(
        {
            label: this.$t("voucher.status"),
            field: "status",
            sortable: false,
        },
        {
            label: this.$t("voucher.actions"),
            field: "actions",
            sortable: false,
            width: "120px",
        }
  )

    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      search: "",
      isLoading: false,
      vouchers: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      partners: [],
      enableDelete: false,
      columns: columns,
      productConfigurationsIds: [2,7,8,10,11]
    };
  },
  async created() {
    window.addEventListener("resize", this.windowResize);
    if (this.userData && this.userData.role === "ROLE_ADMIN") {
      this.enableDelete = true
      await this.getUserList(
        { params: { role: "ROLE_PARTNER" } },
        this.partners
      );
      this.columns.unshift({
        label: this.$t("order.orderId"),
        field: "order",
        sortable: false,
      });
      this.columns.unshift({
        label: this.$t("partner.singular"),
        field: "partner",
        sortable: false,
      });
    }
    this.loadVouchers();
  },
  unmounted() {
    window.removeEventListener("resize", this.windowResize);
  },
  mounted() {
    this.$nextTick(() => {
      this.windowResize();
    });
  },
  methods: {
    orderProductConfigurationsToView(configurations) {
        return configurations.filter(cf => process.env.VUE_APP_VOUCHER_PRODUCT_CONFIGURATION_IDS_TO_VIEW.split(',').includes(cf.productConfiguration.split('/')[2]))
    },
    windowResize() {
      const table = document.querySelector("#voucher-table");
      if (table) {
        const w = table.offsetWidth;
        const collapsibleRows = document.querySelectorAll(
          ".collapsible-content-row"
        );
        collapsibleRows.forEach((row) => {
          row.style.marginTop = `0px`;
        });
        collapsibleRows.forEach((row) => {
          const bw = row.closest("td").clientWidth;
          row.style.width = `${w - 25}px`;
          row.style.marginLeft = `-${w - bw}px`;

          if (window.innerWidth < 767) {
            row.style.marginLeft = `-${w}px`;
          }

          let h = 0;
          let cw = 0;
          const cols = row.closest("tr").querySelectorAll(":scope > td");
          for (const col of cols) {
            if (col.querySelector(".just-buttons")) {
              continue;
            }
            if (h < col.clientHeight) {
              h = col.clientHeight;
            }
            cw += col.clientWidth;
          }

          if (window.innerWidth < 767) {
            row.style.marginLeft = `-${cw}px`;
          }

          row.style.marginTop = `${h}px`;
        });
      }
    },
    searchSubmit(value) {
      this.search = value;
      this.searchVoucher();
    },
    searchVoucher() {
      this.$Vouchers
        .getCollection(
          {
            params: {
              code: this.search.replace(/[^A-Za-z0-9]/g, ""),
              "order[order.createdAt]": "desc",
            },
          },
          "voucher_list"
        )
        .then((response) => {
          if (
            response.data["hydra:member"] &&
            response.data["hydra:member"].length
          ) {
            this.$router.push(
              `${response.data["hydra:member"][0]["@id"]}/edit`
            );
          }
        });
    },
    loadVouchers() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "order[order.createdAt]": "desc",
        status: ["ACTIVATED", "USED", "PAID", "REFUNDED"],
      };
      if (this.userData && this.userData.role === "ROLE_ADMIN") {
        delete params.status;
      }
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Vouchers
        .getCollection({ params }, "voucher_list")
        .then((response) => {
          this.pages = response.data["hydra:totalItems"];
          this.vouchers = response.data["hydra:member"];

          this.vouchers.forEach((voucher) => {
            const voucherVariant = voucher.order.items.find(
              (item) => item.productVariant === voucher.productVariant
            );
            if (voucherVariant) {
              voucher.voucherVarinat = voucherVariant;

              if (voucherVariant.productConfigurations) {
                const productConfigurations = voucherVariant.productConfigurations.filter(c => !this.productConfigurationsIds.includes(c.id))
                if (productConfigurations) {
                    voucher.productConfigurations = productConfigurations
                }
              }
            }
            if (this.userData && this.userData.role === "ROLE_ADMIN") {
              voucher.partner = this.partners.find(
                (partner) => partner.id === voucher.partner
              );
            }
          });
          this.windowResize();
        });
    },
    redeem(voucher) {
      const body = {
        pin: voucher.password,
        code: voucher.code.replace(/[^A-Za-z0-9]/g, ""),
      };
      this.$Vouchers
        .createResourceByUrl({ url: "/vouchers/redeem", body })
        .then((response) => {
          if (response.status === 200) {
            this.loadVouchers();
            this.$helper.showToast(
              useToast(),
              Toast,
              this.$t("voucher.redeemed"),
              null,
              "success"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            this.$emit("clearAction");
            if (error.response.status === 403) {
              this.$helper.showToast(
                useToast(),
                Toast,
                this.$t("forms.incorrectPassword"),
                this.$t("voucher.incorrectPassword"),
                "danger"
              );
            } else {
              this.$helper.showToast(
                useToast(),
                Toast,
                error.response.data["hydra:title"],
                error.response.data["hydra:description"],
                "danger"
              );
            }
          }
        });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadVouchers();
    },
    statusClass(status) {
      let className = "success";
      if (!status) {
        className = "inactive";
      }
      return className;
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadVouchers();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadVouchers();
    },
    onPageChange() {
      this.loadVouchers();
    },
    deleteVoucher(url) {
      this.deleteByUrl(this.$Vouchers, url, this.$t('voucher.deleted'), null, this.loadVouchers, this.error);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
.collapsible-content-row {
  position: relative;
}
</style>
